import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Views/Login';
import Dashboard from './Views/Dashboard';
import BarnPage from './Views/BarnPage';

function App() {
  // This should be replaced with actual authentication logic
  const isAuthenticated = () => {
    // Check if user is authenticated (e.g., by checking for a token in localStorage)
    // return localStorage.getItem('token') !== null;
    return true;
  };

  // const verifyToken = (req, res, next) => {
  //   const bearerHeader = req.headers['authorization'];
    
  //   if (typeof bearerHeader !== 'undefined') {
  //     const bearer = bearerHeader.split(' ');
  //     const bearerToken = bearer[1];
  //     req.token = bearerToken;
      
  //     jwt.verify(req.token, SECRET_KEY, (err, authData) => {
  //       if (err) {
  //         res.sendStatus(403); // Forbidden
  //       } else {
  //         req.user = authData;
  //         next();
  //       }
  //     });
  //   } else {
  //     res.sendStatus(401); // Unauthorized
  //   }
  // };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route 
          path="/dashboard" 
          element={isAuthenticated() ? <Dashboard /> : <Navigate to="/login" />} 
        />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/barn/:barnNum" element={<BarnPage />} />

      </Routes>
    </Router>
  );
}

export default App;