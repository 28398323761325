import React from 'react';
import { ToggleButton, ToggleButtonGroup, styled } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: '#1e1e1e',
  borderRadius: '20px',
  '& .MuiToggleButton-root': {
    color: 'white',
    border: 'none',
    '&.Mui-selected': {
      backgroundColor: '#424242',
      color: 'white',
    },
  },
}));

export function DaySlider({ selectedDay, setSelectedDay, last_loaded_time }) {
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setSelectedDay(newAlignment);
    }
  };

  const dayOfWeek = (daysAgo) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const today = Date.parse(last_loaded_time);
    const targetDate = new Date(today - daysAgo * 24 * 60 * 60 * 1000);
    return days[targetDate.getDay()];
  }

  return (
    <StyledToggleButtonGroup
      value={selectedDay}
      exclusive
      onChange={handleChange}
      aria-label="day selection"
    >
      <ToggleButton value="0">{dayOfWeek(2)}</ToggleButton>
      <ToggleButton value="1">{dayOfWeek(1)}</ToggleButton>
      <ToggleButton value="2">{dayOfWeek(0)}</ToggleButton>
    </StyledToggleButtonGroup>
  );
}

export default DaySlider;