import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import { parse_data } from '../utilities';
import DaySlider from '../Components/daySlider';
import './Dashboard.css'; // Import the CSS file
import DashboardTable from '../Components/dashboardTable';

function Dashboard() {
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedDay, setSelectedDay] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [lastLoadedTime, setLastLoadedTime] = useState(null);

  useEffect(() => {
    fetchDataFromBigQuery();
  }, []);


  const getLastLoadedTime = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.log("Data is not available or is empty");
      return null; // or return a default date if you prefer
    }
  
    // Create a copy of the data array to avoid modifying the original state
    const sortedData = [...data].sort((a, b) => {
      return new Date(a.date.value) - new Date(b.date.value);
    });
  
    // Get the last item's timestamp
    const lastLoadedTime = (sortedData[sortedData.length - 1].timestamp.value).toString().replace('T', ' ').replace('Z', '');
    
    return lastLoadedTime;
  };

  const fetchDataFromBigQuery = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/data');
      response.json().then((data) => {
        setOriginalData(data);
        setData(parse_data(data));
        setLastLoadedTime(getLastLoadedTime(data));
        return data;
      });
    } catch (error) {
      console.error('Error fetching data from backend service:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="dashboard-container">
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className="top-bar">
            <Typography variant="h5">Dashboard</Typography>
          </Box>
          
          <Box className="day-slider-container">
            <DaySlider selectedDay={selectedDay} setSelectedDay={setSelectedDay} last_loaded_time={lastLoadedTime} />
          </Box>

          <DashboardTable 
            data={data[selectedDay]}
            headers={["Barn", "Fuel", "Feed", "Water", "Weight"]}
          />

          <Box className="bottom-nav">
            <div className="updated-at">
              Data last loaded at: <u>{lastLoadedTime}</u>
            </div>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Dashboard;