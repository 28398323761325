import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardTable.css';

const DashboardTable = ({ data, headers }) => {
  const navigate = useNavigate();

  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  const handleBarnClick = (barnNum) => {
    navigate(`/barn/${barnNum}`);

  };

  const addCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '50%' }}>
      <div className="custom-table-container">
        <table className="custom-table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>
                  <button 
                    onClick={() => handleBarnClick(row.barn_num)}
                    className="button-2"
                  >
                    {row.barn_num}
                  </button>
                </td>
                <td>{row.fuel.toFixed(2)} gal.</td>
                <td>{addCommas(row.feed.toFixed(2))} lbs.</td>
                <td>{addCommas(row.water)} gal.</td>
                <td>{row.weight.toFixed(5)} lbs.</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DashboardTable;