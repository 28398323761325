import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Box, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './BarnPage.css';

const BarnPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const { barnNum } = useParams();
    const [fuelData, setFuelData] = useState([]);
    const [feedData, setFeedData] = useState([]);
    const [waterData, setWaterData] = useState([]);
    const [weightData, setWeightData] = useState([]);

    useEffect(() => {
        fetchDataFromBigQuery();
    }, []);

    useEffect(() => {
        parse_data(data);
    }, [data]);

    
    const parse_data = (data) => {
        let parsed_data = {
            "fuel": [],
            "feed": [],
            "water": [],
            "weight": []
        };
        
        for (let i = 0; i < data.length; i++) {
            parsed_data.fuel.push({
                date: data[i].date,
                value: data[i].fuel,
            });
            
            parsed_data.feed.push({
                date: data[i].date,
                value: data[i].feed,
            });
            
            parsed_data.water.push({
                date: data[i].date,
                value: data[i].water,
            });
            
            parsed_data.weight.push({
                date: data[i].date,
                value: data[i].weight,
            });
        }
        setFuelData(get_chart_data(parsed_data.fuel));
        setFeedData(get_chart_data(parsed_data.feed));
        setWaterData(get_chart_data(parsed_data.water));
        setWeightData(get_chart_data(parsed_data.weight));
        return parsed_data;
    }

    const get_chart_data = (data) => {
        let chart_data = [];
        data.forEach((item) => {
            const date = item.date.value
            chart_data.push({
                sort: parseInt(date.toString().split('-')[1] + date.toString().split('-')[2]),
                date: date.toString().split('-')[1] + "/" + date.toString().split('-')[2],
                value: item.value,
            });
        });
        // sort by date
        chart_data = chart_data.sort((a, b) => {
            return a.sort - b.sort;
        });
        return chart_data;
    }

    const fetchDataFromBigQuery = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('/api/data/getChartData/' + barnNum);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setData(data);
            return data;
        } catch (error) {
            console.error('Error fetching data from backend service:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="barn-page">
        <header>
            <button className='back-button' onClick={() => window.history.back()}>Back</button>
            <Typography variant="h4">Barn {barnNum}</Typography>
            <h2></h2>
        </header>
        {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
            </Box>
            ) : (
            <>
            <main>
                <section>
                <h2>Fuel</h2>
                <ResponsiveContainer width="100%" height={200}>
                    <LineChart data={fuelData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
                </section>

                <section>
                <h2>Feed</h2>
                <ResponsiveContainer width="100%" height={200}>
                    <LineChart data={feedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#82ca9d" />
                    </LineChart>
                </ResponsiveContainer>
                </section>

                <section>
                <h2>Water</h2>
                <ResponsiveContainer width="100%" height={200}>
                    <LineChart data={waterData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#ffc658" />
                    </LineChart>
                </ResponsiveContainer>
                </section>

                <section>
                <h2>Weight</h2>
                <ResponsiveContainer width="100%" height={200}>
                    <LineChart data={weightData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#f5f5f5" />
                    </LineChart>
                </ResponsiveContainer>
                </section>
            </main>

            </>
            )}
        </div>
    );
};

export default BarnPage;