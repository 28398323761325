import React, { useState } from 'react';
import './Login.css';
import loginBackground from '../Images/login_background1.png'; // Make sure this path is correct

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const SECRET_KEY='secretkey123';

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    // console.log('Login attempt with:', email, password);
    if (email === 'zem.baptista@gmail.com' && password === 'Friend@10') {
      // // Create the JWT token
      // const token = jwt.sign(
      //   { username: email},
      //   SECRET_KEY,
      //   { expiresIn: '20d' } // Token expires in 1 hour
      // );

      // localStorage.setItem('token', token);

      // Redirect to dashboard
      window.location.href = '/dashboard';

    }
  };

  return (
    <div className="login-container" style={{backgroundImage: `url(${loginBackground})`}}>
      <div className="login-form">
        <h1>Log in</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
          <div className="form-group">
            <a href="#" className="forgot-password">Forgot your password?</a>
          </div>
          <button type="submit" className="login-button">Log in</button>
        </form>
        <div className="signup-link">
          <a href="#">Sign up</a>
        </div>
      </div>
    </div>
  );
}

export default Login;