export function parse_data(data) {
    const data_length = data.length
    const barn_count = data_length / 3
    data = data.sort((a, b) => {
        return new Date(a.date.value) - new Date(b.date.value);
    })
    let sorted_data = {0: [], 1: [], 2: []}
    for (let x = 0; x < 3; x++) {
        for (let i = 0; i < barn_count; i++) {
            sorted_data[x].push(data[i + (barn_count * x)])
        }
        sorted_data[x] = sorted_data[x].sort((a, b) => {
            return a.barn_num - b.barn_num;
        })
    }
    
    return sorted_data
}